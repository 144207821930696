import React from "react";
import "../styles/Versions.css";
import { useDispatch, useSelector } from "react-redux";
import { setVersion } from "store/postSlice";

const Versions = ({ isVertical }) => {
  const dispatch = useDispatch()
  const {version, post} = useSelector(state => state.post)
  const contents = post?.contents || []
  const versionsLength = contents?.length || 0
  const versionArray = new Array(versionsLength).fill(null);
  const changeVersion = (newVersion) => {
    if(newVersion === version) return
    dispatch(setVersion(newVersion))
  }
  return (
    <div
      className={`version-container ${isVertical ? "vertical" : "horizontal"}`}
    >
      {versionArray.map((_, index) => (
        <div
          key={index}
          style={{ display: "inherit", alignItems: "inherit", gap: "inherit", flexDirection: "inherit" }}
        >
          <div
            className={`version-bubble link ${
              version === index + 1 ? "active" : ""
            }`}
            onClick={(e) => changeVersion(index + 1)}
          >
            V{index + 1}
          </div>
          {versionsLength - 1 > index && <div className="version-line"></div>}
        </div>
      ))}
    </div>
  );
};

export default Versions;
