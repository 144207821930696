import { showErrorMessage } from "Utils";
import React from "react";
import { useDispatch } from "react-redux";
import { setIsPreview } from "store/postSlice";
import Swal from "sweetalert2";
import BookMark from "assets/images/post/BookmarkSimple_2.svg";
// import Unlike from "assets/images/post/unlike.svg";
// import Like from "assets/images/post/like.svg";
import "../styles/EditButtons.css";
import Versions from "./Versions";

const EditButtons = ({ savePost, isSaved }) => {
  const dispatch = useDispatch();
  const goToPreview = async () => {
    if (!isSaved) {
      const { isConfirmed } = await Swal.fire({
        icon: "question",
        title: "Question",
        html: "Would you like to save the post?",
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
      if (isConfirmed) {
        const result = await savePost();
        if (!result) {
          showErrorMessage("Failed to save new post");
          return;
        }
      }
    }
    dispatch(setIsPreview(true));
  };
  return (
    <div className="post-buttons">
      <div className="left-buttons">
        <Versions versionsLength={6} currentVersion={1} isVertical={false} />
        <div
          className="button save link"
          onClick={() => {
            if (!isSaved) savePost();
          }}
          style={{ opacity: isSaved ? "40%" : "100%" }}
        >
          <img src={BookMark} alt="save draft" />
          <div className="desktop">SAVE DRAFT</div>
          <div className="mobile">SAVE</div>
        </div>
      </div>

      {/* <div className="buttons">
    
      <div className="button later">
        <img src={CalendarMark} alt="post later" />
        <div>POST LATER</div>
      </div>
    </div> */}
      <div className="right-buttons">
        {/* <div className="desktop">
          <span className="feedback">GIVE FEEDBACK</span>
          <img src={Unlike} alt="unlike" className="link" />
          <img src={Like} alt="like" className="link" />
        </div> */}
        <div className="button review-final link" onClick={goToPreview}>
          Review Final
        </div>
      </div>
    </div>
  );
};

export default EditButtons;
