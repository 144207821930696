import React from "react";
import "../styles/PostCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsPreview } from "store/postSlice";
import useUserInfo from "hooks/useUserInfo";
import { setAuth, setShowUpgrade } from "store/mainSlice";
import { AUTH_STEP, INTERCOM_EVENTS } from "Utils";
import { useIntercom } from "react-use-intercom";
import useInterview from "hooks/useInterview";

const PostCard = ({ post }) => {
  const { posts, articles } = useSelector((state) => state.post);
  const { interviewId } = useInterview();
  const fullyAvailable = posts.every(post => !post.isOpenedFirst) && articles.every(article => !article.isOpenedFirst)
  const { isSubscribed, isAuth, isSignedUp, profile } = useUserInfo();
  const intercom = useIntercom()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToPost = () => {
    if (isAuth) {
      if(isSubscribed || fullyAvailable || post.isOpenedFirst){
        navigate(`/posts/${interviewId}/${post._id}`);
        dispatch(setIsPreview(false));
      } else {
        dispatch(setShowUpgrade(true));
        return;
      }
    } else if (isSignedUp) {
      dispatch(setAuth(AUTH_STEP.sign_in));
      return;
    } else {
      dispatch(setAuth(AUTH_STEP.sign_up));
      if(profile.interviews === 4){
          intercom.trackEvent(INTERCOM_EVENTS.reachedLimit, {
            subscription_link: `${window.location.origin}/subscribe`,
          });
      }
      return;
    }
  };

  const postContent = post?.contents
    ? post.contents[post.contents.length - 1]
    : "";

  const { title, content, tags } = postContent;
  let displayTags = [],
    lastTagsLength = 0;
  if (tags.length < 4) displayTags = tags;
  else {
    displayTags = tags.slice(0, 3);
    lastTagsLength = tags.length - displayTags.length;
  }

  return (
    <div className="post-card">
      <div className="post-card-body">
        <div>
          <div className="post-title">{title}</div>
          <div className="post-content">{content}</div>
        </div>
      </div>
      <div className="post-tags">
        {displayTags.map((tag, index) => (
          <div key={index} className="post-tag">
            {tag.charAt(0) === "#" ? tag : "#" + tag}
          </div>
        ))}
        {lastTagsLength > 0 && (
          <div className="post-tag">{"+" + lastTagsLength}</div>
        )}
      </div>
      <div className="post-buttons">
        <div className="post-review" onClick={goToPost}>
          REVIEW AND POST
        </div>
        {/* { post.isPersonalized &&
          <div className="post-personalized" >
            PERSONALIZED
          </div>} */}
        {/* <div className="post-save">
          <img src={Save} alt="save" />
          <span>SAVE</span>
        </div> */}
      </div>
    </div>
  );
};

export default PostCard;
