import React from "react";
import "../styles/ScheduleCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsPreview } from "store/postSlice";
import JessicaLogo from "assets/images/meeting/meeting-jessica.png";

const ScheduleCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToHome = () => {
    navigate(`/schedule`);
    dispatch(setIsPreview(false));
  };

  return (
    <div className="schedule-card">
      <div className="schedule-img">
        <img src={JessicaLogo} alt="jessica" />
      </div>
      <div className="schedule-review" onClick={goToHome}>
        Schedule your next call
      </div>
    </div>
  );
};

export default ScheduleCard;
