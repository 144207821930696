import React, { useState } from "react";
import "./Counter.css";
import useUserInfo from "hooks/useUserInfo";
import { useDispatch } from "react-redux";
import { setAuth, setBillingInfo } from "store/mainSlice";
import { AUTH_STEP, INTERVIEW_STATUS, showErrorMessage } from "Utils";
import useRedirect from "hooks/useRedirect";
import axios from "axios";
import { SERVER_URL } from "api";
// import axios from "axios";
// import { SERVER_URL } from "api";

const Counter = () => {
  const dispatch = useDispatch();
  const [wait, setWait] = useState(false);
  const {
    profile,
    interviews,
    isAuth,
    isSignedUp,
    isSubscribed,
    isCanceled,
    billingInfo,
  } = useUserInfo();
  const finishedInterviews = interviews.filter(
    (interview) => interview.status === INTERVIEW_STATUS.DONE
  );
  const { firstName } = profile;
  const freeCount = Math.max(2 - finishedInterviews.length, 0);
  const { navigateCreatingRedirect } = useRedirect();

  const subscribe = async () => {
    if (billingInfo && isCanceled) {
      if (wait) return;
      setWait(true);
      try {
        await axios.post(`${SERVER_URL}/billing/uncancel`);
        const newBillingInfo = { ...billingInfo };
        newBillingInfo.isCanceled = false;
        dispatch(setBillingInfo(newBillingInfo));
      } catch (error) {
        console.log(error);
        showErrorMessage(
          error.response?.data?.message ||
            "Failed renew subscription, please try again"
        );
      }
      setWait(false);
    } else {
      navigateCreatingRedirect("/subscribe");
    }
  };

  return (
    <div className={`counter-component width-animation w-full`}>
      <div className="width-animation">
        Hey <span className="capitalize">{firstName}</span>, you have{" "}
        {freeCount} free interviews left.{" "}
        {isAuth && !isSubscribed && (
          <span onClick={subscribe} className="billing-link">
            {billingInfo && isCanceled ? "Resubscribe now" : "Subscribe now"}
          </span>
        )}
        {!isAuth && !isSignedUp && (
          <span
            className="billing-link"
            onClick={() => {
              dispatch(setAuth(AUTH_STEP.sign_up));
            }}
          >
            Sign up now
          </span>
        )}
        {!isAuth && isSignedUp && (
          <span
            className="billing-link"
            onClick={() => {
              dispatch(setAuth(AUTH_STEP.sign_in));
            }}
          >
            Sign in now
          </span>
        )}{" "}
        to get unlimited interviews 🚀
      </div>
      <div className="w-screen fixed -z-10 bg-[#99afff] left-0 top-0 h-10"></div>
    </div>
  );
};

export default Counter;
