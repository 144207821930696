import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import { ReactComponent as Close } from "assets/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import InterviewProgress from "./components/InterviewProgress";
import axios from "axios";
import { SERVER_URL } from "api";
import {
  INTERCOM_EVENTS,
  INTERVIEW_STATE,
  INTERVIEW_STATUS,
  showErrorMessage,
  sleep,
} from "Utils";
import Swal from "sweetalert2";
import {
  setAudio,
  setCountTime,
  setInterviewState,
  setMessages,
  setOpenPopup,
  setPlayingAudio,
  setProgress,
  // setVolume,
} from "store/meetingSlice";
import { useNavigate, useParams } from "react-router-dom";
import MainBox from "./components/MainBox";
import Controller from "./components/Controller";
import AudioPlayer from "./components/AudioPlayer";
import WaitingPlayer from "./components/WaitingPlayer";
import SpeechRecognizer from "./components/SpeechRecognizer";
import { initMeeting } from "store/meetingSlice";
import FillBoard from "./components/FillBoard";
import "./styles/Helper.css";
import Congrats from "./components/Congrats";
// import AudioRecorder from "audio-recorder-polyfill";
import { useIntercom } from "react-use-intercom";
import getBlobDuration from "get-blob-duration";
import { setRegisterEmail } from "store/mainSlice";
import useUserInfo from "hooks/useUserInfo";
import AllowMicroPhone from "./components/AllowMicrophone";
import { setInterviewStatus } from "store/interviewSlice";
import useInterview from "hooks/useInterview";
import { setFetchedPosts } from "store/postSlice";

const Meeting = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intercom = useIntercom();
  const { profile, isChecking } = useUserInfo();
  const { email } = profile;
  const {
    interviewState,
    messages,
    // time,
    openPopup,
  } = useSelector((state) => state.meeting);
  const { interviewId, interviewStatus, topic, fetchInterviewData } =
    useInterview();
  const { interviewId: iId } = useParams();
  // const [audioContext, setContext] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const builtRef = useRef(false);
  // --------- record ---------
  // const animationFrameIdRef = useRef();
  // const recordContextRef = useRef(null);
  // const recordAnalyserRef = useRef(null);
  /*
  0 : interviewer speaking
  1 : user speaking
  2 : user paused speaking
  3 : user finished speaking
  4 : user sending recording
  5 : interview finished
  */

  // const setAudioContext = () => {
  //   setContext(new (window.AudioContext || window.webkitAudioContext)());
  // };

  useEffect(() => {
    builtRef.current = true;
    return () => {
      builtRef.current = false;
    };
  }, []);

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchMessages = async (id) => {
      const messagesResponse = await axios.get(`${SERVER_URL}/messages/${id}`);
      return messagesResponse.data;
    };

    const fetchAllData = async () => {
      if (isChecking || fetched) {
        return;
      }
      try {
        let iIdTemp = interviewId,
          statusTemp = interviewStatus;
        if (iId !== interviewId) {
          const interviewData = await fetchInterviewData(iId);
          iIdTemp = interviewData.interviewId;
          statusTemp = interviewData.interviewStatus;
        }
        if (statusTemp === INTERVIEW_STATUS.CREATED) {
          await axios.post(`${SERVER_URL}/interview-sessions/${iIdTemp}/open`);
          statusTemp = INTERVIEW_STATUS.OPENED;
        }
        if (statusTemp === INTERVIEW_STATUS.DONE) {
          Swal.fire({
            icon: "info",
            title: "Info",
            html: "The interview has been already ended. You will be redirected to the content page shortly.",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          navigate(`/posts/${iIdTemp}`);
          return;
        }
        
        while (true) {
          const messagesResponse = await fetchMessages(iIdTemp);
          const lastMessage = [...messagesResponse].pop();
          if (lastMessage && lastMessage.role === 1) {
            dispatch(setMessages(messagesResponse));
            dispatch(
              setCountTime(
                messagesResponse.reduce(
                  (sum, { duration }) => sum + duration,
                  0
                )
              )
            );
            dispatch(setAudio(`${lastMessage.record}`));
            break;
          }
          await sleep(500);
        }
        dispatch(setInterviewStatus(statusTemp));
        dispatch(setInterviewState(INTERVIEW_STATE.BEFORE_START));
        setFetched(true);
      } catch (error) {
        console.log(error);
        if (error.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "Interview link is wrong",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          navigate("/");
        } else
          showErrorMessage(
            error.response?.data?.message ||
              "Internal server error, please load this page again."
          );
        return;
      }
    };
    if (!fetched) fetchAllData();
  }, [
    dispatch,
    fetchInterviewData,
    fetched,
    iId,
    intercom,
    interviewId,
    interviewStatus,
    isChecking,
    navigate,
  ]);

  const buildRecorder = useCallback(async () => {
    if (!interviewId) return;
    const timer = setTimeout(() => {
      dispatch(setOpenPopup(true));
    }, 1000);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      clearTimeout(timer);
      dispatch(setOpenPopup(false));
      if (!MediaRecorder) {
        // window.MediaRecorder = AudioRecorder;
        showErrorMessage(
          "This browser does not support recording your voice. Please update your browser."
        );
        return;
      }

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.addEventListener("dataavailable", (event) => {
        console.log("ondataavailable");
        // Use a functional update to ensure we capture the most recent state
        if (!event.data) {
          mediaRecorder.start();
          return;
        }
        console.log("ondataavailable1");
        const newChunks = [event.data];
        console.log("ondataavailable2");
        setAudioChunks(newChunks);
        console.log("ondataavailable3");
        const audioBlob = new Blob(newChunks, { type: "audio/mpeg" });
        console.log("ondataavailable4");
        const url = URL.createObjectURL(audioBlob);
        console.log("ondataavailable5");
        dispatch(setAudio(url));
        dispatch(setInterviewState(3));
      });
      mediaRecorder.onerror = (event) => {
        console.error("MediaRecorder error:", event);
      };

      mediaRecorder.onstart = () => {
        console.log("recorder started");
      };

      mediaRecorder.onstop = (event) => {
        console.log("stop");
      };

      streamRef.current = stream;
      mediaRecorderRef.current = mediaRecorder;

      // const recordContext = new (window.AudioContext ||
      //   window.webkitAudioContext)();

      // const recordAnalyser = recordContext.createAnalyser();
      // const source = recordContext.createMediaStreamSource(stream);
      // source.connect(recordAnalyser);
      // recordAnalyser.fftSize = 256;
      // recordContextRef.current = recordContext;
      // recordAnalyserRef.current = recordAnalyser;

      return true;
    } catch (error) {
      clearTimeout(timer);
      dispatch(setOpenPopup(false));
      console.error("Could not start recording", error);
      if (builtRef.current)
        showErrorMessage(
          'You cannot begin speaking without a microphone. Please ensure your microphone is connected and functional. <br/><br/>For Mac -> <a href="https://support.apple.com/guide/mac-help/control-access-to-the-microphone-on-mac-mchla1b1e1fe/mac" target="_blank" rel="noreferrer" style="color: blue; text-decoration: underline;">Help</a><br/>For IOS -> <a href="https://support.apple.com/en-us/101600#:~:text=Go%20to%20Settings%20%3E%20Privacy%20%3E%20Microphone,that%20the%20app%20is%20enabled." target="_blank" rel="noreferrer"  style="color: blue; text-decoration: underline;">Help</a><br/>For Windows -> <a href="https://support.microsoft.com/en-us/windows/turn-on-app-permissions-for-your-microphone-in-windows-10-94991183-f69d-b4cf-4679-c98ca45f577a#:~:text=Here\'s%20how%3A,this%20device%20is%20turned%20on." target="_blank" rel="noreferrer"  style="color: blue; text-decoration: underline;">Help</a><br/>For Android -> <a href="https://www.lifewire.com/turn-on-microphone-on-android-5184530" target="_blank" rel="noreferrer"  style="color: blue; text-decoration: underline;">Help</a><br/><br/>If you need more support, please use the chat in the bottom right corner.'
        );
      return false;
    }
  }, [dispatch, interviewId]);

  useEffect(() => {
    buildRecorder();
  }, [buildRecorder]);

  useEffect(() => {
    const processRecorder = async () => {
      try {
        if (!mediaRecorderRef.current) {
          if (interviewState !== INTERVIEW_STATE.INTERVIEWER_SPEAKING)
            await buildRecorder();
          else return;
        }
        if (interviewState === INTERVIEW_STATE.RECORDING) {
          if (mediaRecorderRef.current.state === "paused") {
            console.log("resume recorder");
            mediaRecorderRef.current.resume();
          } else {
            console.log("start recorder");
            mediaRecorderRef.current.start();
          }
        } else if (interviewState === INTERVIEW_STATE.PAUSED_RECORDING) {
          console.log("pause recorder");
          mediaRecorderRef.current.pause();
        } else if (interviewState === INTERVIEW_STATE.REVIEWING) {
          console.log("stop recorder");
          mediaRecorderRef.current.stop();
        }
      } catch (e) {
        console.log("process recorder error : ", e);
      }
    };
    processRecorder();
  }, [buildRecorder, interviewState]);

  // Request permission and start recording

  // useEffect(() => {
  //   const updateVolume = () => {
  //     if (interviewState !== 1) return;
  //     if (!recordAnalyserRef.current || !recordContextRef.current) return;
  //     const bufferLength = recordAnalyserRef.current.frequencyBinCount;
  //     const dataArray = new Uint8Array(bufferLength);
  //     recordAnalyserRef.current.getByteFrequencyData(dataArray);
  //     const sum = dataArray.reduce((a, b) => a + b, 0);
  //     const average = sum / bufferLength;
  //     const newVolume = average / 255;
  //     // Update the state to include the new volume and keep only the latest samples
  //     dispatch(setVolume(newVolume * 100));
  //     animationFrameIdRef.current = window.requestAnimationFrame(updateVolume);
  //   };
  //   animationFrameIdRef.current = window.requestAnimationFrame(updateVolume);
  //   return () => {
  //     window.cancelAnimationFrame(animationFrameIdRef.current);
  //   };
  // }, [dispatch, interviewState]);

  const startRecording = async () => {
    if (!mediaRecorderRef.current) {
      return await buildRecorder();
    }
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      showErrorMessage(
        'You cannot begin speaking without a microphone. Please ensure your microphone is connected and functional. <br/><br/>For Mac -> <a href="https://support.apple.com/guide/mac-help/control-access-to-the-microphone-on-mac-mchla1b1e1fe/mac" target="_blank" rel="noreferrer">Help</a><br/>For IOS -> <a href="https://support.apple.com/en-us/101600#:~:text=Go%20to%20Settings%20%3E%20Privacy%20%3E%20Microphone,that%20the%20app%20is%20enabled." target="_blank" rel="noreferrer">Help</a><br/>For Windows -> <a href="https://support.microsoft.com/en-us/windows/turn-on-app-permissions-for-your-microphone-in-windows-10-94991183-f69d-b4cf-4679-c98ca45f577a#:~:text=Here\'s%20how%3A,this%20device%20is%20turned%20on." target="_blank" rel="noreferrer">Help</a><br/>For Android -> <a href="https://www.lifewire.com/turn-on-microphone-on-android-5184530" target="_blank" rel="noreferrer">Help</a><br/><br/>If you need more support, please use the chat in the bottom right corner.'
      );
      return false;
    }
    return true;
  };

  const stopRecording = () => {
    try {
      if (!mediaRecorderRef.current) return false;
      mediaRecorderRef.current.stop();
      dispatch(setProgress(0));
      return true;
    } catch (error) {
      console.log("recording stop error : ", error);
      return true
    }
  };

  const uploadRecording = async () => {
    try {
      console.log("upload1", audioChunks);
      const audioBlob = new Blob(audioChunks, { type: "audio/mpeg" });
      console.log("upload2", audioBlob);
      const src = URL.createObjectURL(audioBlob);
      console.log("upload3", audioBlob);
      console.log(`Uploaded Audio Length : ${await getBlobDuration(src)}s`);
      const formData = new FormData();
      console.log("upload4");
      formData.append("audio", audioBlob, "recording.mp3");
      console.log("upload5");
      formData.append("interviewId", interviewId);
      dispatch(setInterviewState(4));
      dispatch(setPlayingAudio(false));
      const response = await axios.post(`${SERVER_URL}/messages/`, formData);
      const { question, answer, isFinished } = response.data;
      const countOfAnswer = (messages.length + 1) / 2;
      if (countOfAnswer === 1) {
        intercom.trackEvent(INTERCOM_EVENTS.submitFirstAnswer, {
          topic,
        });
      } else {
        intercom.trackEvent(INTERCOM_EVENTS.submitAnswer, {
          topic,
          count: countOfAnswer,
        });
      }
      dispatch(setMessages([...messages, answer, question]));
      dispatch(setInterviewState(0));
      if (!isFinished) dispatch(setPlayingAudio(true));
      dispatch(setAudio(`${question.record}`));
      dispatch(setProgress(0));
      setAudioChunks([]);
      if (isFinished) {
        // intercom.trackEvent(INTERCOM_EVENTS.finishInterview, {
        //   topic,
        //   interview_length: time / 1000,
        //   number_of_posts: 5,
        //   number_of_articles: 2,
        //   number_of_questions: Math.ceil(messages.length / 2),
        //   firstName: profile.firstName,
        // });
        dispatch(setInterviewStatus(INTERVIEW_STATUS.DONE));
        dispatch(setRegisterEmail(email));
        dispatch(setFetchedPosts(false));
      }
      return true;
    } catch (error) {
      console.log("Uploading Error : ", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to connect to the server. Please try again.";
      showErrorMessage(errorMessage);
      dispatch(setInterviewState(3));
      return false;
    }
  };

  const reAnswer = () => {
    if (interviewState === 4) return;
    dispatch(setInterviewState(0));
    dispatch(setPlayingAudio(false));
    setAudioChunks([]);
  };

  if (!fetched || isChecking)
    return (
      <div
        className="meeting"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "white", textAlign: "center", fontSize: "36px" }}>
          Loading...
        </h2>
      </div>
    );

  const closeInterview = async () => {
    const { isConfirmed } = await Swal.fire({
      icon: "question",
      title: "Question",
      html: "Would you like to leave the interview?<br/> You can continue the interview anytime.",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
    if (isConfirmed) {
      dispatch(initMeeting());
      navigate("/schedule");
    }
  };

  const startInterview = async () => {
    if (interviewStatus === 0 || interviewStatus === 1) {
      try {
        await axios.post(
          `${SERVER_URL}/interview-sessions/${interviewId}/start`
        );
        intercom.trackEvent(INTERCOM_EVENTS.startInterview, {
          topic,
        });

        // setAudioContext();
        dispatch(setInterviewStatus(2));
        dispatch(setInterviewState(INTERVIEW_STATE.INTERVIEWER_SPEAKING));
        dispatch(setPlayingAudio(true));
      } catch (error) {
        console.log(error);
        showErrorMessage(
          error.response?.data?.message || "Internal server error"
        );
      }
    }
  };

  return (
    <div className="meeting">
      {interviewStatus === INTERVIEW_STATUS.DONE || (
        <div className="mainBoard">
          <div className="close">
            <Close onClick={closeInterview} />
          </div>
          <InterviewProgress />
          <MainBox />
          {interviewStatus === INTERVIEW_STATUS.INTERVIEWING || (
            <FillBoard
              closeInterview={closeInterview}
              startInterview={startInterview}
            />
          )}
        </div>
      )}
      {interviewStatus === INTERVIEW_STATUS.DONE && <Congrats />}
      {interviewStatus === INTERVIEW_STATUS.DONE || (
        <Controller
          startSpeaking={startRecording}
          stopSpeaking={stopRecording}
          sendAnswer={uploadRecording}
          reAnswer={reAnswer}
          // setAudioContext={setAudioContext}
          // audioContext={audioContext}
        />
      )}
      <AudioPlayer /*audioContext={audioContext} */ />
      <WaitingPlayer />
      <SpeechRecognizer />
      {openPopup && <AllowMicroPhone />}
    </div>
  );
};

export default Meeting;
