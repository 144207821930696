import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import Meeting from "pages/Meeting";
import Posts from "pages/Post/Posts";
import "simplebar-react/dist/simplebar.min.css";
import Post from "pages/Post/Edit";
import Review from "pages/Post/Review";
import { useEffect } from "react";
import CustomHelmet from "components/CustomHelmet";
import { AUTH_STEP, PAGE_TYPES } from "Utils";
import Intercom from "components/Intercom";
import Manage from "pages/Manage/Manage";
import AuthPopup from "components/Popups/AuthPopup/AuthPopup";
import UpgradePopup from "components/Popups/UpgradePopup/UpgradePopup";
import Billing from "pages/Billing/Billing";
import CheckoutFailed from "pages/Billing/CheckoutFailed";
import Terms from "pages/Terms/Terms";
import { setAuth, setPageOn } from "store/mainSlice";
import useUserInfo from "hooks/useUserInfo";
import Register from "pages/Registration/Register";
// import Create from "pages/Registration/Create";
import CheckoutSuccess from "pages/Billing/CheckoutSuccess";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Personalization from "pages/Personalization/Personalization";
import Personalized from "pages/Personalization/Personalized";
import AskPersonalization from "pages/Personalization/AskPersonalization";
import Sidebar from "components/Sidebar";
import useLogRocket from "hooks/useLogRocket";
import useAutoManage from "hooks/useAutoManage";
import NotFound from "pages/Etc/NotFound";
import Preparation from "pages/Preparation/Preparation";
import CircleTopics from "pages/Etc/CircleTopics/CircleTopics";

function App() {
  const location = useLocation();
  const { authStep, showUpgrade, pageOn } = useSelector(
    (state) => state.main.status
  );
  const { isAuth } = useUserInfo();
  useLogRocket();
  useAutoManage();
  const { isPreview } = useSelector((state) => state.post);

  // Check if the current route is any of the excluded paths
  // Check if the current route's pathname contains 'meeting'
  const shouldRenderNavbar =
    !location.pathname.includes("not-found") &&
    !location.pathname.includes("meeting") &&
    !location.pathname.includes("personalize") &&
    !location.pathname.includes("post") &&
    !location.pathname.includes("subscribe");

  const shouldRenderSidebar =
    (isAuth &&
      (location.pathname.includes("schedule") ||
        location.pathname.includes("prepare") ||
        location.pathname.includes("posts"))) ||
    location.pathname.includes("prepare");

  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const inviteToken = urlParams.get("inviteToken");
    if (inviteToken) dispatch(setAuth(AUTH_STEP.resetPassword));
  }, [dispatch, location.search]);

  useEffect(() => {
    const url = location.pathname;
    if (url.includes("meeting")) {
      dispatch(setPageOn(PAGE_TYPES.MEETING));
    } else if (url.includes("schedule")) {
      dispatch(setPageOn(PAGE_TYPES.SCHEDULE));
    } else if (url.includes("post")) {
      dispatch(setPageOn(PAGE_TYPES.POSTS));
    } else if (url.includes("person")) {
      dispatch(setPageOn(PAGE_TYPES.PERSONALIZATION));
    } else if (url === "/") {
      dispatch(setPageOn(PAGE_TYPES.REGISTRATION));
    } else {
      dispatch(setPageOn(PAGE_TYPES.MANAGE));
    }
  }, [dispatch, location.pathname]);

  return (
    <div className="App">
      <div className="body">
        <CustomHelmet type={pageOn} />
        <Intercom page={pageOn} />
        {shouldRenderSidebar && <Sidebar />}
        <main className="with-animation">
          {shouldRenderNavbar && <Navbar />}
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/reset-password" element={<Register />} />
            <Route path="/register" element={<Navigate replace to="/" />} />
            <Route path="/schedule" element={<Preparation />} />
            <Route path="/meeting/:interviewId" element={<Meeting />} />
            <Route path="/posts/:interviewId" element={<Posts />} />
            <Route
              path="/posts/:interviewId/post"
              element={<Navigate replace to=".." />}
            />
            <Route
              path="/posts/:interviewId/:postId"
              element={isPreview ? <Review /> : <Post />}
            />
            <Route
              path="/personalize/:interviewId"
              element={<AskPersonalization />}
            />
            <Route path="/personalize" element={<Personalization />} />
            <Route path="/complete-personalize" element={<Personalized />} />
            <Route path="/subscribe" element={<Billing />} />
            <Route path="/subscribe/failed" element={<CheckoutFailed />} />
            <Route path="/subscribe/success" element={<CheckoutSuccess />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/:interviewId" element={<Manage />} />
            <Route path="/linkedin" exact element={<LinkedInCallback />} />
            <Route path="/not-found" element={<NotFound />} />
            {(window.location.origin.includes("stg") ||
              window.location.origin.includes("local")) && (
              <Route path="/circle" element={<CircleTopics />} />
            )}
            <Route path="*" element={<Navigate replace to="/not-found" />} />
          </Routes>
        </main>
        {!!authStep && <AuthPopup />}
        {showUpgrade && <UpgradePopup />}
      </div>
    </div>
  );
}

export default App;
