import { CircularProgress } from "@mui/material";
import { showErrorMessage, showInfoMessage } from "Utils";
import { SERVER_URL } from "api";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPost } from "store/postSlice";
// import Unlike from "assets/images/post/unlike.svg";
// import Like from "assets/images/post/like.svg";
import arrowUp from "assets/images/post-edit/arrow-up.svg";
import "../styles/EditForm.css";

const EditForm = () => {
  const dispatch = useDispatch();
  const [opinion, setOpinion] = useState("");
  const [wait, setWait] = useState(false);
  const { interviewId: iId, postId: pId } = useParams();
  const { post } = useSelector((state) => state.post);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (wait || opinion.trim().length === 0) return;
    if (post?.contents?.length > 5) {
      showInfoMessage(
        "You have reached the maximum limit of 5 changes for this post."
      );
      return;
    }
    setWait(true);
    try {
      const response = await axios.post(`${SERVER_URL}/posts/${iId}/${pId}`, {
        opinion,
      });
      dispatch(setPost(response.data));
      setOpinion("");
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Internal server error"
      );
    }
    setWait(false);
  };
  return (
    <div className="post-actions rounded-b-[20px]">
      <form onSubmit={handleSubmit} className="relative">
        <textarea
          type="text"
          name="opinion"
          id="opinion"
          placeholder="Provide feedback to improve your post..."
          value={opinion}
          onChange={(e) => setOpinion(e.target.value)}
          readOnly={wait}
          rows={3}
        />
        <button
          type="submit"
          className="absolute right-2 top-2 p-2 bg-primary-main rounded-[8px] w-9 h-9 opacity-50 hover:opacity-100"
          style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
        >
          {wait ? (
            <CircularProgress size={20} />
          ) : (
            <img src={arrowUp} alt="submit" className="w-5 h-5" />
          )}
        </button>
      </form>
      <div className="buttons h-6">
        <div>
          {/* <button
            type="submit"
            disabled={wait}
            className="mobile link"
            onClick={handleSubmit}
          >
            {wait && <CircularProgress size={14} />} MAKE CHANGES
          </button> */}
          <div className="step-counter">
            <span style={{ color: "inherit" }}>VERSION </span>
            {post?.contents?.length - 1 || 0}/5
          </div>
          {/* <button
            type="submit"
            disabled={wait}
            className="desktop link"
            onClick={handleSubmit}
          >
            {wait && <CircularProgress size={14} />} MAKE CHANGES
          </button> */}
        </div>
        {/* <div className="mobile reaction">
          <img src={Unlike} alt="unlike" />
          <img src={Like} alt="like" />
        </div> */}
        {/*
        {waitForRe ? (
          <CircularProgress color="secondary" size={28} />
        ) : (
          <img src={Refresh} alt="refresh" onClick={regenerate} />
        )} */}
      </div>
    </div>
  );
};

export default EditForm;
