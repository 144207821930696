import { createSlice } from "@reduxjs/toolkit";
import { get15MinsDate } from "Utils";

export const PREPARATION_STEP = {
  TOPIC_SELECTION: "selecting-topic",
  DATE_SELECTION: "selecting-date",
};

export const RECURRING_VALUES = {
  WEEKLY: "weekly",
  NOT_REPEAT: "not-repeat",
};

const initState = () => ({
  step: PREPARATION_STEP.TOPIC_SELECTION,
  topic: "",
  isNew: false,
  isCustom: false,
  isAI: false,
  isTrending: false,
  isSettingUpAudience: false,
  year: get15MinsDate().getFullYear(),
  month: get15MinsDate().getMonth(),
  date: get15MinsDate().getDate(),
  hour: get15MinsDate().getHours(),
  min: get15MinsDate().getMinutes(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  recurring: RECURRING_VALUES.NOT_REPEAT,
  scheduledId: null,
});

const preparationSlice = createSlice({
  name: "preparation",
  initialState: initState(),
  reducers: {
    setPreparationStep: (state, action) => {
      state.step = action.payload;
    },
    initPreparation: () => {
      return { ...initState() };
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setHour: (state, action) => {
      state.hour = action.payload;
    },
    setMin: (state, action) => {
      state.min = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setRecurring: (state, action) => {
      state.recurring = action.payload;
    },
    setIsSettingUpAudience: (state, action) => {
      state.isSettingUpAudience = !!action.payload;
    },
    setMainTopic: (state, action) => {
      state.topic = action.payload.topic;
      state.isAI = !!action.payload.isAI;
      state.isCustom = !!action.payload.isCustom;
      state.isNew = !!action.payload.isNew;
      state.isTrending = !!action.payload.isTrending;
    },
    removeMainTopic: (state) => {
      state.topic = "";
      state.isAI = false;
      state.isCustom = false;
      state.isNew = false;
      state.isTrending = false;
    },
    setScheduledId: (state, action) => {
      state.scheduledId = action.payload;
    },
  },
});

export const {
  setPreparationStep,
  initPreparation,
  setDate,
  setHour,
  setMin,
  setMonth,
  setTimezone,
  setYear,
  setRecurring,
  setIsSettingUpAudience,
  setMainTopic,
  removeMainTopic,
  setScheduledId,
} = preparationSlice.actions;

export default preparationSlice.reducer;
