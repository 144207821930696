import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPreview: false,
  /*
  0 : posts and articles
  1 : edit post
  2 : review post
  3 : done post
  */
  post: null,
  version: -1,
  posts: [],
  articles: [],
  postId: null,
  interviewId: null,
  topic: "",
  firstName: null,
  fullName: "",
  picture: null,
  officialFirstName: null,
  officialLastName: null,
  headline: "111111111122222222223333344444555556666677777777778888888888", //"\u00A0",
  email: null,
  linkedin: "",
  fetchedPosts: false
  /*
  0 : interviewer speaking
  1 : user speaking
  2 : user paused speaking
  3 : user finished speaking
  4 : user sending recording
  5 : interview finished
  */
};

const meetingSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload.map((post) => ({
        ...post,
        edited: false,
      }));
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setPost: (state, action) => {
      const haveToSetVersion =
        !!action.payload &&
        (!state.version ||
          !state.post ||
          state.post._id !== action.payload._id ||
          state.post.contents.length !== action.payload.contents.length);
      state.post = action.payload;
      if (haveToSetVersion) state.version = action.payload.contents.length;
    },
    updatePost: (state, action) => {
      if (state.version === 0 || !state.post?.contents) return;
      let newContents = [...state.post.contents];
      newContents.splice(state.version - 1, 1, action.payload);
      state.post = { ...state.post, contents: newContents };
    },
    setIsPreview: (state, action) => {
      state.isPreview = action.payload;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setFetchedPosts: (state, action) => {
      state.fetchedPosts = !!action.payload
    }
  },
});

export const {
  setPosts,
  setArticles,
  setPost,
  setIsPreview,
  updatePost,
  setVersion,
  setCode,
  setFetchedPosts
} = meetingSlice.actions;

export default meetingSlice.reducer;
