import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style.css";
// import Close from "assets/images/close.svg";
import ArrowLeft from "assets/images/ArrowLeft.svg";
// import PencilSample from "assets/images/post/PencilSimple.svg";
import PostAside from "components/PostAside/PostAside";
import { useNavigate, useParams } from "react-router-dom";
import ReviewCard from "./components/ReviewCard";
import { useDispatch, useSelector } from "react-redux";
import { setIsPreview } from "store/postSlice";
import {
  AUTH_STEP,
  INTERCOM_EVENTS,
  showErrorMessage /* showSuccessMessage */,
} from "Utils";
import PoweredBy from "components/PoweredBy/PoweredBy";
import { useIntercom } from "react-use-intercom";
import SuccessPopup from "components/Popups/SuccessPopup/SuccessPopup";
import { setAuth, setProfile } from "store/mainSlice";
import axios from "axios";
import { LINKEDIN_CLIENT_ID, SERVER_URL } from "api";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";
// import Counter from "components/Counter/Counter";

const Review = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const { interviewId: iId, postId: pId } = useParams();
  // const { interviewId: iId } = useParams();
  const { post, version } = useSelector((state) => state.post);
  const { topic } = useInterview();
  const [showPopup, setShowPopup] = useState(false);
  const [wait, setWait] = useState(false);
  const [run, setRun] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const linkedinCodeRef = useRef(null);
  const { profile } = useUserInfo();
  const options = useMemo(
    () => ({
      clientId: LINKEDIN_CLIENT_ID,
      redirectUri: `${window.location.origin}/linkedin`,
      scope: "openid profile w_member_social email",
      onSuccess: (code) => {
        linkedinCodeRef.current = code;
        setRun(true);
        setIsConnecting(false);
      },
      onError: (error) => {
        console.log(error);
        if (error.error !== "user_closed_popup") {
          showErrorMessage("Failed to connect your linkedin account");
        }
        setIsConnecting(false);
      },
    }),
    []
  );
  const { linkedInLogin } = useLinkedIn(options);
  const submit = () => {
    if (isConnecting) return;
    if (profile.linkedinConnected) {
      publishPost();
    } else {
      setIsConnecting(true);
      linkedInLogin();
    }
  };
  const publishPost = useCallback(async () => {
    if (wait) return;
    setRun(false);
    setWait(true);
    const body = {
      version: version - 1,
    };
    if (linkedinCodeRef.current) {
      body.code = linkedinCodeRef.current;
      linkedinCodeRef.current = null;
    }
    try {
      intercom.trackEvent(INTERCOM_EVENTS.clickOnPostIt, { topic });
      await axios.post(`${SERVER_URL}/posts/${iId}/${pId}/publish`, body);
      if (linkedinCodeRef.current) {
        dispatch(setProfile({ linkedinConnected: true }));
      }
      setShowPopup(true);
      intercom.trackEvent(INTERCOM_EVENTS.succeededPublish, {
        link: `https://www.linkedin.com/in/${profile.linkedin}/recent-activity/all/`,
        firstName: profile.firstName,
      });
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        dispatch(setAuth(AUTH_STEP.sign_in));
        navigate(`/posts/${iId}`);
        return;
      }
      showErrorMessage(
        error.response?.data?.error || "Failed to publish your post"
      );
    }
    linkedinCodeRef.current = null;
    setWait(false);
  }, [
    dispatch,
    iId,
    intercom,
    navigate,
    pId,
    profile.firstName,
    profile.linkedin,
    topic,
    version,
    wait,
  ]);

  useEffect(() => {
    if (run) {
      publishPost();
    }
  }, [publishPost, run]);

  const goBack = () => dispatch(setIsPreview(false));
  if (!post)
    return (
      <div
        className="review"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "white", textAlign: "center", fontSize: "36px" }}>
          Loading...
        </h2>
      </div>
    );

  // const copyToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(post.contents[version - 1])
  //     .then(async () => {
  //       intercom.trackEvent(INTERCOM_EVENTS.clickOnPostIt, { topic });
  //       // const { isConfirmed } = await showSuccessMessage(
  //       //   `Content of this ${
  //       //     post.isArticle ? "article" : "post"
  //       //   } copied to clipboard`
  //       // );
  //       // if (isConfirmed) {
  //       //   navigate(`/posts/${iId}/done`);
  //       // }
  //       setShowPopup(true)
  //     })
  // .catch((error) => {
  //   console.error("Error copying text: ", error);
  // });
  // };

  // const goToPosts = () => navigate(`/posts/${iId}`);
  return (
    <div className="review">
      <PostAside />
      <section>
        {/* <Counter /> */}
        <div className="close">
          <div className="button">
            <img src={ArrowLeft} alt="back" onClick={goBack} />
          </div>
          {/* <div className="button" onClick={goToPosts}>
            <img src={Close} alt="close" />
          </div> */}
        </div>
        <div className="content">
          <div className="title">Let's review the post and post it</div>
          <ReviewCard />
          <div className="review-buttons">
            <div className="button button-save" onClick={goBack}>
              {/* <img src={PencilSample} alt="back to edit" /> */}
              <div>BACK TO EDIT</div>
            </div>
            <div>
              Feel ready?
              <div className="button button-review" onClick={submit}>
                {wait || isConnecting ? "WAIT..." : "POST IT"}
              </div>
            </div>
          </div>
        </div>
        <PoweredBy />
      </section>
      {showPopup && <SuccessPopup setOpenPopup={setShowPopup} />}
    </div>
  );
};

export default Review;
