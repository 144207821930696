import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import ArrowLeft from "assets/images/ArrowLeft.svg";
import smileIcon from "assets/images/post/Smiley.svg";
import imgIcon from "assets/images/post/ImageSquare.svg";
import PostAside from "components/PostAside/PostAside";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "api";
import {
  setArticles,
  setFetchedPosts,
  setPost,
  setPosts,
  updatePost,
} from "store/postSlice";
import { AUTH_STEP, INTERVIEW_STATUS, md2plain, showErrorMessage } from "Utils";
import Swal from "sweetalert2";
import Reader from "./components/Reader";
import EditForm from "./components/EditForm";
import EditButtons from "./components/EditButtons";
import PoweredBy from "components/PoweredBy/PoweredBy";
import Versions from "./components/Versions";
// import Counter from "components/Counter/Counter";
import useUserInfo from "hooks/useUserInfo";
import { setAuth, setShowUpgrade } from "store/mainSlice";
import EmojiPicker from "./components/EmojiPicker";
import Trash from "assets/images/post-edit/trash-blue.svg";
import ImageUploader from "./components/ImageUploader";
import useError from "hooks/useError";
import Copy from "assets/images/post-edit/copy.svg";
import { setInterviewId } from "store/interviewSlice";
import useInterview from "hooks/useInterview";

const Post = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { interviewId, interviewStatus, fetchInterviewData } = useInterview();
  const { interviewId: iId, postId: pId } = useParams();
  const [isSaved, setIsSaved] = useState(true);
  const {
    post,
    version,
    fetchedPosts,
    posts: originalPosts,
    articles: originalArticles,
  } = useSelector((state) => state.post);
  const [text, setText] = useState("");
  const [showImageUploader, setShowImageUploader] = useState(false);
  const { isAuth, isChecking, isSignedUp, isSubscribed, interviews } =
    useUserInfo();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { isErrorStatus } = useError();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (interviewId !== iId) {
      dispatch(setInterviewId(iId));
    }
  }, [interviewId, dispatch, iId]);

  useEffect(() => {
    const fetchData = async () => {
      if (isChecking || fetched) return;
      try {
        let iIdTemp = interviewId,
          statusTemp = interviewStatus;
        if (iId !== interviewId) {
          const interviewData = await fetchInterviewData(iId);
          iIdTemp = interviewData.interviewId;
          statusTemp = interviewData.interviewStatus;
          dispatch(setInterviewId(iIdTemp));
        }
        if (statusTemp < INTERVIEW_STATUS.DONE) {
          navigate(`/meeting/${iIdTemp}`);
          return;
        }
        let posts = originalPosts,
          articles = originalArticles;
        if (!fetchedPosts) {
          let response = await axios.get(`${SERVER_URL}/posts/${iIdTemp}`);
          posts = response.data.posts;
          articles = response.data.articles;
          dispatch(setPosts(posts));
          dispatch(setArticles(articles));
          dispatch(setFetchedPosts(true));
        }

        let response = await axios.get(
          `${SERVER_URL}/posts/${iIdTemp}/${pId}`
        );
        const fullyAvailable =
          posts.every((post) => !post.isOpenedFirst) &&
          articles.every((article) => !article.isOpenedFirst);
        if (!fullyAvailable) {
          const interviewIndex = interviews.findIndex(
            (interview) => interview._id === iIdTemp
          );
          if (interviewIndex > 1) {
            let index = posts.findIndex((content) => content._id === pId);
            if (index < 0) {
              index = articles.findIndex((content) => content._id === pId);
              if (index < 0) {
                console.log("strange");
              } else {
                const content = articles[index];
                content.isOpenedFirst = true;
                articles.splice(index, 1, content);
              }
            } else {
              const content = posts[index];
              content.isOpenedFirst = true;
              posts.splice(index, 1, content);
            }
          }
        }
        dispatch(setPost(response.data));
        setFetched(true);
      } catch (error) {
        console.log(error);
        if (
          await isErrorStatus(error, 401, () => {
            dispatch(
              setAuth(isSignedUp ? AUTH_STEP.sign_in : AUTH_STEP.sign_up)
            );
            navigate(`/posts/${iId}`);
          })
        )
          return;
        if (
          await isErrorStatus(error, 403, () => {
            dispatch(setShowUpgrade(true));
            navigate(`/posts/${iId}`);
          })
        )
          return;
        if (
          await isErrorStatus(error, 404, () => {
            navigate(`/not-found`);
          })
        )
          return;
        // showErrorMessage(
        //   error.response?.data?.message || "Internal server error"
        // );
        navigate("/not-found");
      }
    };
    if (!isChecking) {
      // if (!isAuth) {
      //   const message = isSignedUp
      //     ? "Please sign in to access this content"
      //     : "Please sign up to access this content";
      //   showErrorMessage(message);
      //   navigate(`/posts/${interviewId}`);
      // } else {
      fetchData();
      // }
    }
  }, [
    dispatch,
    iId,
    pId,
    navigate,
    isChecking,
    isAuth,
    isSubscribed,
    interviews,
    isSignedUp,
    isErrorStatus,
    interviewId,
    fetchedPosts,
    fetched,
    originalPosts,
    originalArticles,
    interviewStatus,
    fetchInterviewData,
  ]);

  useEffect(() => {
    setText(post?.contents[version - 1] || "");
  }, [post?.contents, version]);

  const textareaRef = useRef(null);

  useEffect(() => {
    setIsSaved(text === post?.contents[version - 1]);
  }, [post?.contents, version, text]);

  const savePost = async () => {
    try {
      await axios.put(`${SERVER_URL}/posts/${interviewId}/${pId}`, {
        content: text,
        version,
      });
      dispatch(updatePost(text));
      return true;
    } catch (error) {
      if (
        await isErrorStatus(error, 401, () => {
          dispatch(setAuth(isSignedUp ? AUTH_STEP.sign_in : AUTH_STEP.sign_up));
          navigate(`/posts/${interviewId}`);
        })
      )
        return false;
      showErrorMessage("Failed to save the post. Please try again.");
      console.log(error);
      return false;
    }
  };

  const goToPosts = async () => {
    if (!isSaved) {
      const { isConfirmed, isDismissed } = await Swal.fire({
        icon: "question",
        title: "Question",
        html: "Would you like to save the post?",
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Yes, of course",
        denyButtonText: "No, thanks",
      });
      if (isConfirmed) {
        const result = await savePost();
        if (!result) {
          showErrorMessage("Failed to save new post");
          return;
        }
      } else if (isDismissed) {
        return;
      }
    }
    navigate(`/posts/${interviewId}`);
  };

  const onEmojiClick = useCallback((emojiObject) => {
    if (emojiObject.emoji) {
      if (cursorStartIndexRef.current > -1) {
        setText(
          (t) =>
            t.slice(0, cursorStartIndexRef.current) +
            emojiObject.emoji +
            t.slice(cursorEndIndexRef.current)
        );
        // textareaRef.current.focus();
        // if(scrollYRef.current >= 0){
        //   postBodyRef.current.scrollTo({top: scrollYRef.current})
        //   scrollYRef.current = -1
        // }
        setTimeout(() => {
          textareaRef.current.setSelectionRange(
            cursorStartIndexRef.current + 2,
            cursorStartIndexRef.current + 2
          );
        }, 0);
      } else {
        setText((t) => t + emojiObject.emoji);
        if (emojiObject.emoji) textareaRef.current.focus();
      }
    }
    setShowEmojiPicker(false);
  }, []);

  const pickerRef = useRef(null);
  const cursorStartIndexRef = useRef(-1);
  const cursorEndIndexRef = useRef(-1);
  const scrollYRef = useRef(0);
  const postBodyRef = useRef(null);

  useEffect(() => {
    const closePicker = (e) => {
      if (!showEmojiPicker) return;
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        onEmojiClick({ emoji: "" });
      }
    };
    window.addEventListener("click", closePicker);
    return () => {
      window.removeEventListener("click", closePicker);
    };
  }, [onEmojiClick, showEmojiPicker]);

  useEffect(() => {
    if (!postBodyRef.current) return;
    const handleScroll = () => {
      scrollYRef.current = postBodyRef.current.scrollTop;
    };

    const textAreaElement = textareaRef.current;
    textAreaElement.addEventListener("blur", handleScroll);

    return () => {
      textAreaElement.removeEventListener("blur", handleScroll);
    };
  }, [post]);

  const [resized, setResized] = useState({});

  useEffect(() => {
    const triggerResized = () => {
      setResized({});
    };
    window.addEventListener("resize", triggerResized);
    return () => {
      window.removeEventListener("resize", triggerResized);
    };
  }, []);

  useEffect(() => {
    const textArea = textareaRef.current;
    if (textArea) {
      textArea.style.height = `${textareaRef.current.scrollHeight}px`;
      const divElement = postBodyRef.current;
      divElement.scrollTop = scrollYRef.current;
    }
    return () => {
      if (textArea) {
        textArea.style.height = 0;
      }
    };
  }, [text, resized]);

  const deleteImage = async () => {
    try {
      await axios.post(`${SERVER_URL}/posts/${interviewId}/${pId}/image`);
      dispatch(setPost({ ...post, images: [] }));
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to delete the image");
    }
  };

  const copyContent = () => {
    navigator.clipboard
      .writeText(md2plain(text)) // Copy the URL to the clipboard
      .then(() => {
        console.log("URL copied to clipboard: " + text);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard: ", error);
      });
  };

  if (!post || !fetched)
    return (
      <div
        className="post"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "black", textAlign: "center", fontSize: "36px" }}>
          Loading...
        </h2>
      </div>
    );
  return (
    <div className="post">
      <PostAside onClick={() => setShowImageUploader(false)} />
      <section className="short:relative sm:relative">
        {/* <Counter /> */}
        <Versions versionsLength={3} currentVersion={1} isVertical={true} />
        <div className="section-main">
          <div className="close">
            <div className="button">
              <img src={ArrowLeft} alt="back" onClick={goToPosts} />
            </div>
            {/* <div className="button" onClick={goToPosts}>
              <img src={Close} alt="close" />
            </div> */}
          </div>
          <div className="content">
            <div className="title">
              Let's review the {post.isArticle ? "article" : "post"} and post it
            </div>
            <div className="description">
              Here you have the first draft, you can edit it by yourself or just
              tell me in the chat below how you want to improve it
            </div>
            <Reader text={text} />
            <div className="post-edit border border-dashed border-primary-main rounded-[20px]">
              <div className="overflow-hidden flex-1 w-full rounded-t-[20px]">
                <div className="post-body h-full">
                  <div
                    className="absolute overflow-auto h-full p-5 scroll-dark w-full rounded-t-[20px] "
                    ref={postBodyRef}
                  >
                    <textarea
                      className="post-div"
                      ref={textareaRef}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      onBlur={(e) => {
                        cursorStartIndexRef.current = e.target.selectionStart;
                        cursorEndIndexRef.current = e.target.selectionEnd;
                        // scrollYRef.current = postBodyRef.current.scrollTop;
                      }}
                    />
                    {post?.images?.length > 0 && (
                      <div className="w-full relative">
                        <img
                          src={post.images ? post.images[0] : undefined}
                          //src={post.images.pop()}
                          alt="post"
                          className="w-full h-auto rounded-lg"
                        />
                        <div className="rounded-full p-1.5 bg-white stroke-[1px] stroke-blue-400 absolute right-4 top-5 cursor-pointer">
                          <img
                            src={Trash}
                            alt="remove"
                            className="w-6 h-6"
                            onClick={deleteImage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-5 h-auto w-full flex-row px-4 py-2">
                <div className="flex gap-5 flex-row">
                  <div
                    className="relative short:static sm:relative"
                    ref={pickerRef}
                  >
                    <img
                      src={smileIcon}
                      alt="smile"
                      className="w-8 h-8 cursor-pointer"
                      onClick={() => setShowEmojiPicker((val) => !val)}
                    />
                    {showEmojiPicker && (
                      <EmojiPicker onEmojiClick={onEmojiClick} />
                    )}
                  </div>
                  <img
                    src={imgIcon}
                    alt="img"
                    className="w-8 h-8 cursor-pointer"
                    onClick={() => setShowImageUploader(true)}
                  />
                </div>
                <div
                  className="flex px-4 py-1.5 flex-row items-center gap-2 rounded-[40px] border border-solid border-[#9daac0] cursor-pointer active:opacity-60"
                  onClick={copyContent}
                >
                  <img src={Copy} alt="copy" />
                  <span
                    className="font-figtree text-[14px] font-[700] leading-[160%] tracking-[0.4px]"
                    style={{ color: "#9daac0" }}
                  >
                    Copy
                  </span>
                </div>
              </div>
              <EditForm />
            </div>
          </div>
          <EditButtons isSaved={isSaved} savePost={savePost} />
          <PoweredBy />
        </div>
        {showImageUploader && <ImageUploader setShow={setShowImageUploader} />}
      </section>
    </div>
  );
};

export default Post;
