import React from "react";
import ReactMarkdown from "react-markdown";
import "./ReviewCard.css";
import { MoreHoriz } from "@mui/icons-material";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";

const ReviewCard = () => {
  const { post, version } = useSelector((state) => state.post);
  const { profile } = useUserInfo();
  const { fullName, headline, picture } = profile;
  return (
    <div className="review-card">
      <div className="review-head">
        <img className="avatar" src={picture} alt="user" />
        <div className="detail">
          <div className="name">
            <strong className="capitalize">{fullName}</strong>
          </div>
          <div className="company desktop">
            {headline?.length > 50 ? headline?.slice(0, 50) + "..." : headline}
          </div>
          <div className="company mobile">
            {headline?.length > 25 ? headline?.slice(0, 25) + "..." : headline}
          </div>
          <div className="period">
            1w •
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              className="mercado-match"
              width="16"
              height="16"
              focusable="false"
            >
              <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
            </svg>
          </div>
        </div>
        <MoreHoriz />
      </div>
      <div className="review-content">
        <SimpleBar>
          <ReactMarkdown children={post.contents[version - 1]} />
          {post?.images?.length > 0 && (
            <img
              src={post?.images[0]}
              alt="post"
              className="w-full h-auto rounded-lg"
            />
          )}
        </SimpleBar>
      </div>
    </div>
  );
};

export default ReviewCard;
