import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import { initPreparation } from "store/preparationSlice";
import { useNavigate } from "react-router-dom";
import Jessica from "assets/images/preparation/jessica-light-blue.png";
import { getCurrentDateTime } from "Utils";

const ScheduledPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useUserInfo();
  const { firstName } = profile;
  const {
    scheduledId,
    isAI,
    isCustom,
    isNew,
    isTrending,
    topic,
    year,
    month,
    date,
    hour,
    min,
    timezone,
  } = useSelector((state) => state.preparation);
  // const popupRef = useRef(null);

  // const closePopup = (e, force = false) => {
  //   if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
  //     dispatch(setScheduledId(null));
  //   }
  // };

  const reschedule = () => {
    dispatch(initPreparation());
  };

  const start = async () => {
    dispatch(initPreparation());
    navigate(`/meeting/${scheduledId}`);
  };

  const getDateTimeString = useCallback(() => {
    let startDate = new Date(year, month, date, hour, min);
    let endDate = new Date(year, month, date, hour, min + 15);

    if (hour < 0) {
      const today = getCurrentDateTime(timezone);
      startDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute
      );
      endDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute + 15
      );
    }

    const formatDate = (date) => {
      const options = { weekday: "long", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      const suffix = getNumberSuffix(date.getDate());

      return `${formattedDate}${suffix}, ${year}`;
    };

    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedStartDate = `${startDate.getHours()}:${String(
      startDate.getMinutes()
    ).padStart(2, "0")}`;
    const formattedEndDate = `${endDate.getHours()}:${String(
      endDate.getMinutes()
    ).padStart(2, "0")}`;

    const result = `${formattedStartDate} - ${formattedEndDate} ${formatDate(
      startDate
    )}`;
    return result;
  }, [date, hour, min, month, timezone, year]);

  const timeString = getDateTimeString();

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex flex-col items-center z-[100] p-2.5 top-0 left-0 overflow-auto"
      // onClick={closePopup}
    >
      <div className="flex-1 flex items-center max-w-full">
        <div
          className="relative rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[972px] max-w-full p-4 pt-8 md:p-8 md:pt-16 gap-4 flex flex-col items-center"
          id="audience-popup"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          // ref={popupRef}
        >
          <span className="text-black-bg text-center text-[27px] sm:text-[36px] leading-[103%] font-eighties-it w-full">
            Your Call is scheduled
          </span>
          <div className="flex flex-col items-start gap-2 max-w-full">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-5 mb-2">
              <img
                src={Jessica}
                alt="jessica"
                className="w-24 h-auto flex-shrink-0"
              />
              <div className="gap-2 flex flex-col flex-1 items-start">
                <div className="capitalize font-eighties text-[20px] sm:text-[27px] font-[500] leading-[140%]">{`${firstName} <> Jessica`}</div>
                <div className="font-eighties-it text-[20px] sm:text-[24px] leading-[120%]">
                  Personalized content creation interview
                </div>
              </div>
            </div>
            <div className="font-figtree text-[16px] font-[700] py-2">
              {timeString}
            </div>
            <div className="font-figtree text-[14px]">
              <div>Voice interview, no need for a web camera</div>
              <div className="text-text-secondary">
                <strong>Time Zone</strong> <span>{timezone}</span>
              </div>
            </div>
            <div className="font-figtree text-black-bg text-[16px] font-[500] gap-4 flex flex-col max-w-full">
              <div className="py-3">Chosen Topic(s):</div>
              <div className="px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base">
                <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1">
                  {isAI && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      AI Suggestion
                    </div>
                  )}
                  {isTrending && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                      🔥Trending
                    </div>
                  )}
                  {isNew && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                      🌟New
                    </div>
                  )}
                  {isCustom && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                      Custom
                    </div>
                  )}
                </div>
                <div className="text-ellipsis overflow-x-clip text-nowrap w-full">
                  {topic}
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center gap-4 flex-wrap py-4">
            <button
              type="submit"
              className="link interact max-w-full w-[207px] py-2 sm:py-3 text-primary-main bg-transparent border border-primary-main font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={reschedule}
            >
              Schedule another Call
            </button>
            <button
              type="submit"
              className={`max-w-full w-[207px] py-2 sm:py-3 bg-primary-main text-white border border-primary-main interact font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]`}
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={start}
            >
              Start Call Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledPopup;
