import React, { useCallback, useEffect, useRef, useState } from "react";
import Jessica from "assets/images/meeting/meeting-jessica.png";
import "../styles/Reader.css";
import axios from "axios";
import { SERVER_URL } from "api";
import { CircularProgress } from "@mui/material";
import waitReading from "assets/audios/wait_reading.mp3"

const Reader = ({ text }) => {
  const [wait, setWait] = useState(false);
  const [reading, setReading] = useState(false);
  const audioRef = useRef(null);
  const audioURLRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const clearReading = useCallback(() => {
    // Once playback is finished, revoke the Object URL
    setReading(false);
    setWait(false);
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.removeEventListener("ended", clearReading);
    }
    if (audioURLRef.current) URL.revokeObjectURL(audioURLRef.current);
    audioRef.current = null;
    audioURLRef.current = null;
  }, []);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        clearReading()
      }
    };
  }, [clearReading]);

  const readPost = async () => {
    if (reading) {
      clearReading();
      return;
    }
    if (audioRef.current || wait) return;
    try {
      setWait(true);
      setReading(false);
      const response = await axios.post(`${SERVER_URL}/services/read`, {
        text,
      });
      if(!isMounted.current) return
      const audioBuffer = response.data.audio.data;
      const uint8Array = new Uint8Array(audioBuffer);
      const blob = new Blob([uint8Array], { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      audioURLRef.current = audioUrl;
      setReading(true);
      audio.play();
      audio.addEventListener("ended", clearReading);
    } catch (error) {
      console.log(error);
    }
    setWait(false);
  };
  return (
    <div className="reader">
      <div className="ask-read">
        <img src={Jessica} alt="jessica" />
        <span>Would you like me to read it for you?</span>
      </div>
      <div className="link interact bg-blue button" onClick={readPost}>
        {wait && <CircularProgress size={14} />}
        {wait ? "Wait" : reading ? "Stop Reading" : "Yes Jessica"}
      </div>
      {wait && <audio src={waitReading} autoPlay></audio> }
    </div>
  );
};

export default Reader;
