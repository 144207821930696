import { createSlice } from "@reduxjs/toolkit";
import { INTERVIEW_STATUS } from "Utils";

const initialState = {
  interviewId: null,
  status: INTERVIEW_STATUS.CREATED,
  topic: "",
};

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    setInterviewId: (state, action) => {
      state.interviewId = action.payload;
    },
    setInterviewStatus: (state, action) => {
      state.status = action.payload;
    },
    setTopic: (state, action) => {
      state.topic = action.payload
    },
    initInterviewData: () => {
      return {...initialState};
    }
  },
});

export const {
  setInterviewId,
  setInterviewStatus,
  setTopic,
  initInterviewData
} = interviewSlice.actions;

export default interviewSlice.reducer;
