import React, { useEffect, useRef, useState } from "react";
import "./style.css";
// import Close from "assets/images/close.svg";
import SimpleBar from "simplebar-react";
import PostCard from "./components/PostCard";
import PostAside from "components/PostAside/PostAside";
import { useDispatch, useSelector } from "react-redux";
import {
  INTERVIEW_STATUS,
  POST_STATUS,
  capitalize,
  showErrorMessage,
  sleep,
} from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import { useNavigate, useParams } from "react-router-dom";
import {
  setArticles,
  setPosts,
  setPost,
  setFetchedPosts,
} from "store/postSlice";
import LoadingPopUp from "components/Popups/LoadingPopUp/LoadingPopUp";
// import Link from "assets/images/Link.svg";
import Check from "assets/images/post/check.svg";
import PoweredBy from "components/PoweredBy/PoweredBy";
import Counter from "components/Counter/Counter";
import Navbar from "components/Navbar";
import Tooltip from "components/Tooltip/Tooltip";
import InfoIcon from "assets/images/info-gray-icon.svg";
import ScheduleCard from "./components/ScheduleCard";
import useUserInfo from "hooks/useUserInfo";
import useCookie from "hooks/useCookie";
import useInterview from "hooks/useInterview";
import { setInterviewId } from "store/interviewSlice";

const Posts = () => {
  const { interviewId: id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { posts, articles, fetchedPosts } = useSelector((state) => state.post);
  const { interviewId, interviewStatus, topic, fetchInterviewData } =
    useInterview();
  const { isSubscribed, profile, isChecking, isAuth } = useUserInfo();
  const { firstName } = profile;
  const [showPopup, setShowPopup] = useState(true);
  const [seemsPayed, setSeemsPayed] = useState(false);
  const { getCookiePayed } = useCookie();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    setSeemsPayed(!!getCookiePayed());
  }, [getCookiePayed]);

  const destroyedRef = useRef(false);

  useEffect(() => {
    return () => {
      destroyedRef.current = true;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isChecking || !id) return;
      if (fetchedPosts) {
        setFetched(true);
        return;
      }
      try {
        let iIdTemp = interviewId,
          statusTemp = interviewStatus;
        if (id !== interviewId) {
          const interviewData = await fetchInterviewData(id);
          iIdTemp = interviewData.interviewId;
          statusTemp = interviewData.interviewStatus;
          dispatch(setInterviewId(iIdTemp));
        }
        if (statusTemp !== INTERVIEW_STATUS.DONE) {
          navigate(`/meeting/${iIdTemp}`);
          return;
        }
        let count = 0;
        let response = null;
        while (count < 3) {
          response = await axios.get(`${SERVER_URL}/posts/${iIdTemp}`);
          if (response.data.status === POST_STATUS.SUCCESS) break;
          if (response.data.status === POST_STATUS.FAILED) {
            count++;
          }
          if (!destroyedRef || destroyedRef.current) return;
          await sleep(5000);
        }
        if (count >= 3) {
          showErrorMessage(
            "Failed to generate posts. Please refresh the page to try again!"
          );
          return;
        }
        dispatch(setArticles(response.data.articles));
        dispatch(setPosts(response.data.posts));
        dispatch(setFetchedPosts(true));
        setFetched(true);
      } catch (error) {
        console.log(error);
        // showErrorMessage(
        //   error.response?.data?.message || "Internal server error"
        // );
        navigate("/not-found");
      }
    };
    if (!isChecking) fetchData();
  }, [
    dispatch,
    fetchInterviewData,
    fetchedPosts,
    id,
    interviewId,
    interviewStatus,
    isChecking,
    navigate,
  ]);

  useEffect(() => {
    dispatch(setPost(null));
  }, [dispatch]);

  // const copyCurrentURL = () => {
  //   const currentURL = window.location.href; // Get the current URL
  //   navigator.clipboard
  //     .writeText(currentURL) // Copy the URL to the clipboard
  //     .then(() => {
  //       console.log("URL copied to clipboard: " + currentURL);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to copy URL to clipboard: ", error);
  //     });
  // };

  const personalize = async () => {
    navigate(`/personalize?interviewId=${interviewId}`);
  };
  // get the date of the post
  function formatDate(date) {
    // Months array to convert month index to month name
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the day of the month
    const day = date.getDate();

    // Get the month index and year
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Function to define the correct ordinal indicator ('st', 'nd', 'rd', or 'th')
    function getOrdinalIndicator(day) {
      if (day > 3 && day < 21) return "th"; // teens always end in 'th'
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Build the string
    return `${months[monthIndex]} ${day}${getOrdinalIndicator(day)}, ${year}`;
  }
  const formattedDate =
    posts.length > 0 ? formatDate(new Date(posts[0].createdAt)) : "";

  return (
    <div className="posts width-animation">
      {fetched && !showPopup && (
        <>
          {!isAuth && (
            <PostAside
              postsLength={posts.length}
              articlesLength={articles.length}
            />
          )}
          <section>
            {!isChecking && !isSubscribed && !seemsPayed && <Counter />}
            <div className="scrollbar-container">
              <SimpleBar>
                <Navbar />
                <div className="topic mobile capitalize">
                  <strong>Jessica &lt;&gt; {capitalize(firstName)}</strong>{" "}
                  <br />
                  Topic: {topic}
                </div>
                <div className="description mobile">
                  <div>
                    <div>Interview summary:</div>
                    <div>{posts.length + articles.length} Assets created</div>
                    <div>
                      <div>
                        <strong>{posts.length} posts </strong> &{" "}
                        <strong>{articles.length} articles</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="head">
                  <div className="title info-container">
                    <div>Here are your {posts.length} Linkedin posts about</div>
                    <div className="infos flex-wrap-reverse sm:flex-nowrap">
                      <div className="info-date">Created: {formattedDate}</div>
                      {/* <div
                        className="link interact copy-link desktop"
                        onClick={copyCurrentURL}
                      >
                        <img src={Link} alt="link" /> Copy link
                      </div> */}
                      <Tooltip
                        text="Copy the link and store it somewhere safe. These posts disappear when you close the tab."
                        className="desktop flex-shrink-0"
                      >
                        <img src={InfoIcon} alt="info" />
                      </Tooltip>
                      <div
                        className="link interact copy-link"
                        onClick={personalize}
                      >
                        <img src={Check} alt="link" />
                        <span style={{ color: " #0e24d6" }}>
                          {profile.personalized
                            ? "Tone of Voice"
                            : "Set up your tone of voice"}
                        </span>
                        {profile.personalized || (
                          <span className="hidden xl:inline">
                            to Improve your posts
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="title">{topic}</div>
                </div>
                <div className="content">
                  {posts.map((post, index) => (
                    <PostCard key={index} post={post} />
                  ))}
                </div>
                <div className="head">
                  <div className="title">
                    Here are your {articles.length} Linkedin articles about
                  </div>
                  <div className="title">{topic}</div>
                </div>
                <div className="content">
                  {articles.map((post, index) => (
                    <PostCard key={index} post={post} />
                  ))}
                  <ScheduleCard />
                </div>
                <PoweredBy />
              </SimpleBar>
            </div>
          </section>
        </>
      )}
      {(showPopup || isChecking) && (
        <LoadingPopUp setShowPopup={setShowPopup} fetched={fetched} />
      )}
    </div>
  );
};

export default Posts;
