import { MenuItem, Select } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RECURRING_VALUES, setRecurring } from "store/preparationSlice";

const RecurringSelector = () => {
  const dispatch = useDispatch();
  const { recurring } = useSelector((state) => state.preparation);
  const handleChangeTime = (e) => {
    console.log(e.target.value);
    
    dispatch(setRecurring(e.target.value));
  };
  const options = useMemo(() => {
    return [
      { label: "Do not repeat", value: RECURRING_VALUES.NOT_REPEAT },
      { label: "Weekly", value: RECURRING_VALUES.WEEKLY },
    ];
  }, []);
  return (
    <Select
      labelId="recurring-label"
      id="recurring-select"
      value={recurring}
      onChange={handleChangeTime}
      sx={{ borderRadius: "30px" }}
      fullWidth
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value} sx={{ width: "100%" }}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RecurringSelector;
