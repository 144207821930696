import React from "react";
import Logo from "assets/images/Logo-black-blue.png";
import "./PoweredBy.css"

const PoweredBy = () => {
  return (
    <div className="powered-by mobile">
      <div>Powered by</div>
      <a href="https://www.q-ueue.ai/?utm_source=Jessica&utm_medium=powerby" target="_blank" rel="noreferrer">
        <img src={Logo} alt="logo-black-blue" className="link" />
      </a>
    </div>
  );
};

export default PoweredBy
