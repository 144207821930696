import React from "react";
import TimeSelector from "./TimeSelector";
import Calendar from "./Calendar";
import TimeZoneSelector from "./TimeZoneSelector";
import { useDispatch, useSelector } from "react-redux";
import TrashIcon from "assets/images/preparation/trash-01.svg";
import RecurringSelector from "./RecurringSelector";
import {
  PREPARATION_STEP,
  removeMainTopic,
  setPreparationStep,
} from "store/preparationSlice";
import { capitalize } from "Utils";
import useUserInfo from "hooks/useUserInfo";

const DateSelector = ({ waitStart, waitAdd, createSchedule }) => {
  const dispatch = useDispatch();
  const { topic, isNew, isCustom, isTrending, /*isAI,*/ tag } = useSelector(
    (state) => state.preparation
  );
  const profile = useUserInfo();
  const wait = waitStart || waitAdd;
  return (
    <div className="flex-1 w-full flex justify-center items-center">
      <div className="max-w-full">
        <div className="mb-6 text-black-bg font-figtree text-[24px] leading-[87.5%]">
          Choose time and date
        </div>
        <div className="p-4 lg:p-8 w-[900px] max-w-full rounded-[30px] bg-off-white-super-light grid grid-cols-1 sm:grid-cols-2 gap-7 mb-32 sm:mb-0 date-selector-card">
          <div className="flex flex-col gap-4 w-full">
            <div className="text-black-bg font-figtree text-[16px] font-[500] py-3 hidden sm:block date-selector-card-desktop">
              Choose time and date or start the call now
            </div>
            <div className="font-figtree text-black-bg text-[16px] font-[500] gap-2 flex sm:hidden flex-col date-selector-card-mobile">
              <div className="py-3">Chosen Topic(s):</div>
              <div className="px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base">
                <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1">
                  {/* {isAI && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      AI Suggestion
                    </div>
                  )} */}
                  {tag === "Topics" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      ✨Topic
                    </div>
                  )}
                  {tag?.trim() === "Company Questions" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                      {`🏢Question about ${capitalize(profile.company)}`}
                    </div>
                  )}
                  {tag?.trim() === "Expertise Questions" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                      {`Question about ${capitalize(profile.fullName)}`}
                    </div>
                  )}
                  {isTrending && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                      🔥Trending
                    </div>
                  )}
                  {isNew && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                      🌟New
                    </div>
                  )}
                  {isCustom && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                      Custom
                    </div>
                  )}
                </div>
                <div className="text-ellipsis overflow-x-clip text-nowrap w-[calc(100%-32px)]">
                  {topic}
                </div>
                <img
                  src={TrashIcon}
                  alt="delete"
                  className={
                    "w-6 h-6 flex-shrink-0" + wait ? "" : " interact link"
                  }
                  onClick={() => {
                    if (wait) return;
                    dispatch(removeMainTopic());
                    dispatch(
                      setPreparationStep(PREPARATION_STEP.TOPIC_SELECTION)
                    );
                  }}
                />
              </div>
            </div>
            <div className="w-full block sm:hidden date-selector-card-mobile">
              <RecurringSelector />
            </div>
            <TimeSelector />
            <Calendar />
            <TimeZoneSelector />
          </div>
          <div className="date-selector-card-desktop hidden flex-col gap-8 sm:flex">
            <div className="font-figtree text-black-bg text-[16px] font-[500] gap-5 flex flex-col">
              <div className="py-3">Chosen Topic(s):</div>
              <div className="px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base">
                <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1">
                  {/* {isAI && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      AI Suggestion
                    </div>
                  )} */}
                  {tag === "Topics" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      ✨Topic
                    </div>
                  )}
                  {tag?.trim() === "Company Questions" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                      {`🏢Question about ${capitalize(profile.company)}`}
                    </div>
                  )}
                  {tag?.trim() === "Expertise Questions" && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                      {`Question about ${capitalize(profile.fullName)}`}
                    </div>
                  )}
                  {isTrending && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                      🔥Trending
                    </div>
                  )}
                  {isNew && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                      🌟New
                    </div>
                  )}
                  {isCustom && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                      Custom
                    </div>
                  )}
                </div>
                <div className="text-ellipsis overflow-x-clip text-nowrap w-[calc(100%-32px)]">
                  {topic}
                </div>
                <img
                  src={TrashIcon}
                  alt="delete"
                  className={
                    "w-6 h-6 flex-shrink-0" + wait ? "" : " interact link"
                  }
                  onClick={() => {
                    if (wait) return;
                    dispatch(removeMainTopic());
                    dispatch(
                      setPreparationStep(PREPARATION_STEP.TOPIC_SELECTION)
                    );
                  }}
                />
              </div>
            </div>
            <RecurringSelector />
            <div className="flex w-full gap-4 flex-wrap py-4">
              <button
                className={`flex-1 border ${
                  wait
                    ? "border-action-disabled-background text-action-disabled"
                    : "border-primary-main text-primary-main interact"
                } rounded-full py-2 px-5 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] whitespace-nowrap`}
                disabled={wait}
                onClick={() => createSchedule(false)}
              >
                {waitStart ? "Wait..." : "Start Now"}
              </button>
              <button
                className={`flex-1 ${
                  wait
                    ? "bg-action-disabled-background text-action-disabled"
                    : "border border-primary-main bg-primary-main text-white interact"
                } rounded-full py-2 px-5 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] whitespace-nowrap`}
                disabled={wait}
                onClick={() => createSchedule(true)}
              >
                {waitAdd ? "Wait..." : "Add to Calendar"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="date-selector-card-mobile date-selector-action-card border border-off-white-dark rounded-t-[30px] mt-12 bottom-0 w-screen fixed sm:hidden bg-off-white-light z-[2]">
        <div className="w-full flex flex-col items-center gap-2 p-4">
          <button
            className={`interact w-[388px] max-w-full rounded-[40px] border ${
              wait
                ? " bg-action-disabled-background text-action-disabled"
                : "border-primary-main bg-primary-main text-white"
            } py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
            disabled={wait}
            onClick={() => createSchedule(true)}
          >
            {waitAdd ? "Wait..." : "Add to Calendar"}
          </button>
          <button
            className={`interact w-[388px] rounded-[40px] max-w-full interact border-none ${
              wait ? "text-action-disabled" : "text-primary-main"
            }  py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
            onClick={() => createSchedule(false)}
            disabled={wait}
          >
            {waitStart ? "Wait..." : "Start Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateSelector;
