import React from "react";
import Room from "./Room";
import { useSelector } from "react-redux";
import { INTERVIEW_STATUS } from "Utils";
import Transcription from "./Transcription";
import InterviewTips from "./InterviewTips";
import useUserInfo from "hooks/useUserInfo";

const MainBox = () => {
  const { topic, status } = useSelector((state) => state.interview);
  const { firstName, headline } = useUserInfo().profile;
  const cutHeadline =
    headline.length > 30 ? headline.slice(0, 30) + "..." : headline;
  return (
    <div
      className={`main${status === INTERVIEW_STATUS.DONE ? " finished" : ""}`}
    >
      <div className="names capitalize">
        Jessica Que &lt;&gt; {firstName} | {cutHeadline}
      </div>
      <div className="topic">
        Topic: {topic.length > 100 ? topic.slice(0, 100) + "..." : topic}
      </div>
      <div className="conversation">
        <InterviewTips />
        <Room />
        <Transcription />
      </div>
    </div>
  );
};

export default MainBox;
