import React, { useRef, useState } from "react";
// import HelpCircle from "assets/images/help-circle.svg";
import { showErrorMessage } from "Utils";
import axios from "axios";
import { BASE_SERVER_URL, SERVER_URL } from "api";
import { useDispatch } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import { setProfile } from "store/mainSlice";
import warning from "assets/images/schedule/warning.svg";

const AddLinkedinPopup = ({
  setOpen,
  parentWait,
  isWeekly = false,
  callback = () => {},
}) => {
  const dispatch = useDispatch();
  const { profile } = useUserInfo();
  const popupRef = useRef(null);
  const [linkedin, setLinkedin] = useState("");
  const [wait, setWait] = useState(false);
  const [adding, setAdding] = useState(false);

  const closePopup = (e, force = false) => {
    if (wait || parentWait) return;
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      setOpen(false);
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    if (wait || parentWait) return;
    if (linkedin.trim().length === 0) {
      showErrorMessage("Please input your linkedin profile url");
      return;
    }
    let cutLinkedin = linkedin
      .trim()
      .replace(/^\/+|\/+$/g, "")
      .replace(/\s/g, "")
      .split("/")
      .pop();
    setWait(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/services/linkedin/${cutLinkedin}`
      );

      const {
        officialFirstName,
        officialLastName,
        company,
        picture,
        headline,
      } = response.data;

      await axios.put(`${BASE_SERVER_URL}/api/v2/users/assistant/profile`, {
        linkedInProfileUrl: `https://www.linkedin.com/in/${cutLinkedin}/`,
      });
      dispatch(
        setProfile({
          ...profile,
          officialFirstName,
          officialLastName,
          company,
          picture,
          linkedin: cutLinkedin,
          headline,
          // title
        })
      );
      callback(isWeekly, cutLinkedin, {
        officialFirstName,
        officialLastName,
        company,
        picture,
        headline,
      });
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        showErrorMessage("LinkedIn URL is invalid");
        return;
      }
      showErrorMessage(
        "Failed to submit your linkedin profile link, please try again"
      );
    }
    setWait(false);
  };

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex items-center justify-center z-[100] min-h-max p-2.5 top-0"
      onClick={closePopup}
    >
      <div
        className="relative rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[519px] max-w-100%"
        style={{
          boxShadow:
            "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
        }}
        ref={popupRef}
      >
        {adding ? (
          <form
            className="flex w-full flex-col p-4 sm:p-10 gap-6"
            onSubmit={submit}
          >
            <h3 className="font-eighties font-[600] text-[28px] sm:text-[42px]">
              Let's book your call
            </h3>
            <div className="w-full flex flex-col gap-4">
              <input
                type="text"
                name="first-name"
                id="first-name"
                placeholder="Enter your first name"
                className="rounded-[38px] px-5 py-2 sm:py-3 border-none outline-none font-fig text-[16px] sm:text-[18px] font-[400] leading-[150%] tracking-0 text-left"
                value={profile.firstName}
                readOnly
              />
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                className="rounded-[38px] px-5 py-2 sm:py-3 border-none outline-none font-fig text-[16px] sm:text-[18px] font-[400] leading-[150%] tracking-0 text-left"
                value={profile.email}
                readOnly
              />
              <div className="w-full flex flex-col items-start gap-2">
                <input
                  type="text"
                  name="linkedin"
                  id="linkedin"
                  placeholder="Your Linkedin profile URL:"
                  className="rounded-[38px] px-5 py-2 sm:py-3 border-none outline-none font-fig text-[16px] sm:text-[18px] font-[400] leading-[150%] tracking-0 text-left w-full"
                  value={linkedin}
                  readOnly={wait || parentWait}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                <span className="text-new-gray font-figtree text-[12px] leading-[150%] sm:ml-5">
                  {"Example:  https://www.linkedin.com/in/{your_profile_id}"}
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="link interact w-full py-2 sm:py-3 bg-primary-main text-white font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              disabled={wait || parentWait}
            >
              {wait || parentWait ? "Wait..." : "Continue"}
            </button>
          </form>
        ) : (
          <div className="flex flex-col gap-6 px-6 py-16">
            <div className="flex flex-col gap-3 items-center">
              <div className="p-4 rounded-[12px] bg-orange-mishmish">
                <img src={warning} alt="error" className="w-6 h-6" />
              </div>
              <div className="text-gray-900 font-figtree text-[16px] leading-[125%] tracking-[-0.16px] flex flex-col gap-3 items-center">
                <span className="font-[700]">Error</span>
                <span className="text-center">
                  Please provide your LinkedIn profile URL to schedule your
                  call.
                </span>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <button
                className="link interact py-2 px-12 sm:py-3 bg-primary-main text-white font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
                onClick={() => {
                  setAdding(true);
                }}
              >
                Add Linkedin URL
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddLinkedinPopup;
